import Superfast_Delivery from "../../../assests/Superfast-Delivery.png"
import React,{useEffect, useState} from "react";
import Best_Prices_Offers from "../../../assests/Best-Prices-Offers.png"
import Wide_Assortment from "../../../assests/Wide-Assortment.png"
import Easy_Returns_Refund from "../../../assests/Easy-Returns-Refund.png"

const ProductDetailTabs = ({productData}) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) => activeIndex === index ? className : "";
    return (<>
      <div className="tabs">
        <button
          className={`tab ${checkActive(1, "active")}`}
          onClick={() => handleClick(1)}
        >
          Product Features
        </button>
        <button
          className={`tab ${checkActive(2, "active")}`}
          onClick={() => handleClick(2)}
        >
          Product Specifications
        </button>
        <button
          className={`tab ${checkActive(3, "active")}`}
          onClick={() => handleClick(3)}
        >
          Product Description
        </button>
      </div>
      <div className="panels">
        <div className={`panel ${checkActive(1, "active")}`}>          
          <div dangerouslySetInnerHTML={{ __html: productData.product_features }} class="font_paragraph long_description"/>
        </div>
        <div className={`panel ${checkActive(2, "active")}`}>
          <div dangerouslySetInnerHTML={{ __html: productData.product_specification }} class="font_paragraph"/>
        </div>
        <div className={`panel ${checkActive(3, "active")}`}>
          {productData?.other_details ? productData?.other_details.map((details, index) => (
            <div class="ProductAttribute__ProductAttributes-sc-dyoysr-0 bqhpsC">
              <p class="ProductAttribute__ProductAttributesName-sc-dyoysr-1 kORRGZ font_basic">
              <span>{details.title}</span>
              </p>
              <div class="ProductAttribute__ProductAttributesDescription-sc-dyoysr-2 lnLDYa font_paragraph font_13">
              {details.description ? details.description :  '---'}
              </div>
            </div>        
          )): null}
        </div> 
      </div>




    </>)
}

export default ProductDetailTabs;