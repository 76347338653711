import React,{useState, useEffect} from "react";
import "./selectedCarouselPage.css"
import { Header,Footer } from "../shared-components";
import ProductCard from "../shared-components/productCard/productCard";
import {useParams} from "react-router-dom"
import {carouselProductList, cartItemsCount} from "../../utils/apiUrlsSetup"
import Loader from "../shared-components/loader/loader";
const pincode = localStorage.getItem("pincode") || "";

const SelectedCarouselPage = () => {

    const {id} = useParams()
    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {} ;

    const [productsList, setProductsList] = useState([])
    const [showLoader, setShowLoader] = useState(false)
    const [badgeCount, setBadgeCount] = useState(0)
    const [badgePrice, setBadgePrice] = useState(0)

    useEffect(()=>{
        if(id){
            getCarouselProductList()
            window.scrollTo(0, 0)
        }
    },[])

    const getCartItemsCount = async() =>{
        const res = await cartItemsCount({})
        if(res.ErrorCode === 0){
            setBadgeCount(res?.Data?.total_count)
            setBadgePrice(res?.Data?.total_price)      
        }
    }

    const getCarouselProductList = async() =>{
        setShowLoader(true)
        const formData = new FormData()
        formData.append("carousel_id", id)
        const data = await carouselProductList(formData)
        if(data.ErrorCode === 0){
            setShowLoader(false)
            setProductsList(data.Response.carousels_list.items)
        }
    }

    return(
        <>
        {showLoader ? <Loader right="50%" bottom="47%" /> : null}
        <Header userDetails={userDetails} badgeCount={badgeCount} />
        <div className="row m-0 selected_brand_page px-3 px-lg-0">
            <div className="col-0 col-lg-2"></div>
            <div className="col-0 col-lg-8 px-2 py-4 p-lg-4 mb-4 rounded">
            {productsList && productsList.length ? 
            <div className={`row m-0 d-flex ps-1 ms-1`}>
                {/* <p className="font_500 text-secondary p-0">Brand : <span className="text-dark fw-bold">{name}</span> </p> */}
                <ProductCard 
                    catProductList={productsList} 
                    setCatProductList={setProductsList} 
                    brandProduct
                    getCartItemsCount={getCartItemsCount}
                />
            </div>
            :
            <div className="row m-0 d-flex justify-content-center align-items-center empty_data_row">
                <p className="font_500 text-secondary text-center p-0">No Products Available</p>
            </div>
            }
            </div>
            <div className="col-0 col-lg-2"></div>
        </div>
        <Footer />
        </>
    )
}

export default SelectedCarouselPage ;
