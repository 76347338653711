
import React, { Suspense} from 'react';
import { Route, Routes } from "react-router-dom";

import {
  Home, ProductPage,
} from "./components";
import AddressPage from "./components/addressPage/addressPage"
import ProductCategoryWise from "../src/components/productCategoryWise/productCategoryWise"
import ProductCategoryPage from "../src/components/productCategoryWise/productCategoryPage"
import ProfilePage from "../src/components/profilePage/profilePage";
import OrderPage from "../src/components/orderPage/orderPage"
import ReturnItemPage from './components/returnItemPage/returnItemPage';
import ReturnRefundPage from "./components/returnRefundPage/returnRefundPage"
import BrandPage from "./components/brandPage/brandPage"
import SelectedBrandPage from './components/selectedBrandPage/selectedBrandPage';
import SelectedCarouselPage from './components/selectedCarouselPage/selectedCarouselPage';
import SelectedPageView from './components/selectedPageView/selectedPageView';
import FooterAllCategoryDetails from './components/footerAllCategoryDetails/footerAllCategoryDetails';
import AboutUs from "./components/staticLinkPages/AboutUs"
import ContactUs from "./components/staticLinkPages/ContactUs"
import PrivacyPolicy from "./components/staticLinkPages/PrivacyPolicy"
import ShippingPolicy from "./components/staticLinkPages/ShippingPolicy"
import RefundAndReplace from "./components/staticLinkPages/RefundAndReplace";
import TermAndCondition from "./components/staticLinkPages/TermAndCondition";
import Review from "./components/review/review"
import Rating from './components/rating/rating';

const RouterMain = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
    <Routes>
     <Route path="/" element={<Home />}></Route>
     <Route path='/product/:name/:id' element={<ProductPage/>}></Route>
     <Route path="/product-category/:id/" element={<ProductCategoryPage />}></Route>
     <Route path="/product-category/:id/:Id" element={<ProductCategoryWise />}></Route>
     <Route path="/account/addresses" element={<AddressPage />}></Route>
     <Route path="/account/profile" element={<ProfilePage />}></Route>
     <Route path="/account/order" element={<OrderPage />}></Route>
     <Route path="/account/order/return/:id" element={<ReturnItemPage />}></Route>
     <Route path="/account/order/return/:number/:id" element={<ReturnRefundPage />}></Route>
     <Route path="/brands" element={<BrandPage />}></Route>
     <Route path="/brand/:name/:id" element={<SelectedBrandPage />}></Route>
     <Route path="/carousel/:id" element={<SelectedCarouselPage />}></Route>
     <Route path="/categories" element={<FooterAllCategoryDetails />}></Route>
     <Route path="/contact-us" element={<ContactUs />}></Route>
     <Route path="/about-us" element={<AboutUs />}></Route>
     <Route path="/privacy_policy" element={<PrivacyPolicy />}></Route>
     <Route path="/shipping_policy" element={<ShippingPolicy />}></Route>
     <Route path="/refund" element={<RefundAndReplace />}></Route>
     <Route path="/term_&_condition" element={<TermAndCondition />}></Route>
     <Route path="/review/:id" element={<Review />}></Route>
     <Route path="/rating/:id" element={<Rating />}></Route>
     <Route path="/pages/:id" element={<SelectedPageView />}></Route>
    </Routes>
    </Suspense>
  );
};

export default RouterMain;
