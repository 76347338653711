import React, { useState } from "react";
import productImg from "../../../assests/product_img.png"
import "./orderCard.css"
import { reOrder } from "../../../utils/apiUrlsSetup"
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify"

const OrderCard = (props) => {

    const navigate = useNavigate()

    const handleReorder = async (order_id) => {
        const formData = new FormData()
        formData.append("order_number", order_id)
        const response = await reOrder(formData)
        
        if (response.ErrorCode === 0) {
            props.setReorderOpenCart(true)
            props.getCartBadgeCount()
             toast.success('Item Added Successfully', { autoClose: 1200 })
        }
        else{
            props.handleError(response.ErrorMessage)
        }
    }

    return (
        <>
            {props.orderList && props.orderList.length ? props.orderList.map((value, index) => {
                // console.log('value: ', value);
                return <div className="order_card row m-0 mt-3 mx-1 mx-md-3 rounded" key={index}
                
                
                >
                    <div className="row m-0 p-3 bg_greyshade justify-content-between">
                        <div className="col-8 ms-5 ms-md-0 p-0">

                            <p className="m-0 order_deatils_id font_basic fs-5"> {value.orderId || value.request_no}</p>
                            <p className="m-0 order_deatils_price font-13 text-secondary font_family_common">Total Amount - ₹{value.amount || value.total ? Math.floor(value.amount) || Math.floor(value.total) : "00"}</p>
                            {value.quantity ? <p className="m-0 text-secondary">Qty : {value.quantity}</p> : null}
                            <p
                                className="m-0 order_deatils_payment_method font-13 text-secondary font_family_common"
                            >
                                {value.payment_mode ? "Payment Method -  " : null}
                                <span
                                    className="font_500 fs-6 font_family_common"
                                >
                                    {value.payment_mode}
                                </span>
                            </p>
                        </div>
                        <div className="col-2 p-0 d-flex justify-content-end d-none d-md-flex">
                            <p
                                className="m-0 order_new_tag font_13 py-1 d-inline-block rounded text-white px-2 font_family_common"
                            >
                                {value.current_status ? value.current_status : value.return_type === 1 ? "Returned" : value.return_type === 2 ? "Replaced" : value.current_status}
                            </p>
                        </div>
                    </div>
                    {value.reason ?
                        <div className="row m-0 border-bottom font_family_common">
                            {value.reason ? <p className="m-0 text-secondary px-1 py-2"><span className="font_basic">Reason :</span> {value.reason}</p> : null}
                        </div>
                        : null}

                    {["Delivered","Completed"].includes(value.current_status) && value.orderId ?
                        <div className="border-bottom d-flex justify-content-end">
                            <Link
                                type="button"
                                className="bg_green px-3 py-2 text-white font_basic rounded me-1 my-1"
                                to={`/review/${value.orderId}`}
                            >
                                Review & Rating
                            </Link>
                            <button
                                type="button"
                                className="bg_green px-3 py-2 text-white font_basic rounded me-1 my-1 ms-2"
                                onClick={() => { handleReorder(value.orderId) }}
                            >
                                Reorder
                            </button>
                        </div>
                        : null}
                    {["Shipped"].includes(value.current_status) && value.orderId ?
                        <div className="border-bottom d-flex justify-content-end">
                            <Link
                                type="button"
                                className="bg_green px-3 py-2 text-white font_basic rounded me-1 my-1"
                                to={`/review/${value.liveTrackLink}`}
                            >
                                Track Your Order
                            </Link>
                        </div>
                        : null}

                    <div className="row m-0 order_card_bottom">
                        <div className="col-8 p-0">
                            {value.return_type ?
                                <p className="m-0 text-secondary py-2 font_family_common"><span className="font_basic">{value.return_type === 1 ? 'Returned at' : 'Repalced at'}</span> {value.created_at}</p>
                                :
                                <p className="m-0 text-secondary py-2 font_family_common">Placed on {value.order_date}</p>
                            }
                        </div>
                        <div
                            className="col-4 p-0 d-md-flex justify-content-end align-items-center text_green py-2 white-space-wrap"
                        >
                            <div className="d-flex justify-content-end d-md-none">
                                <p
                                    className="m-0 order_new_tag font_13 py-1 d-inline-block rounded text-white px-2 font_family_common d-md-none"
                                >
                                    {value.current_status ? value.current_status : value.return_type === 1 ? "Returned" : value.return_type === 2 ? "Replaced" : value.current_status}
                                </p>
                            </div>
                            <span className="d-flex justify-content-end mt-1">
                                {!value.return_type ? <><span className="cursor-pointer" onClick={() => { props.getOrderDetails(value.orderId) }}>View details</span>
                                    <span>
                                        <i className="fa-solid fa-play ms-2 mt-1"></i>
                                    </span></> : null}
                            </span>
                        </div> 
                    </div>
                </div>

            }) : null}
        </>
    )
}

export default OrderCard;