import {categoryData} from "../../../utils/apiUrlsSetup"
import React,{useState, useEffect} from "react"
const FooterCategories = () => {

    const pincode = localStorage.getItem("pincode") || '' ;

    const [categoryListData, setCategoryListData] = useState([]) ;

    useEffect(()=>{
        getCategoryData()
    },[])

    const getCategoryData = async()=>{
        let response = await categoryData({"pincode":pincode})
        if(response && response.ErrorCode === 0){
            setCategoryListData(response.Response.category)
        }
    }

    return (
        <div>
            <div className="FooterLinks__HeadingContainer-sc-12rhzht-5 bZlgle d-flex justify-content-between me-lg-5 pe-lg-5">
                <div className="FooterLinks__ListHeading-sc-12rhzht-1 aonOx font_heading">
                    Categories
                </div>
                <a
                    className="FooterLinks__SeeAll-sc-12rhzht-4 flTNGy font_500 me-2 me-lg-0 font_green"
                    href="/categories"
                >
                    <span className="float-right font_basic flTNGy">See All</span>
                </a>
            </div>
            <ul type="col" className="FooterLinks__List-sc-12rhzht-2 jPIBOn category_list_container">
                {categoryListData.map((value,index)=>{
                    return <li className="FooterLinks__ListItem-sc-12rhzht-3 BvTiN font_paragraph" key={index}>
                        <a href={`/product-category/${value.category_slug}`}>
                            {value.category_name}
                        </a>
                    </li>
                })}
            </ul>
        </div>
    )
}

export default FooterCategories;