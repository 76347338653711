import axios from "axios";
import { toast } from "react-toastify"

const CallApiWithoutAuth = async (options = {}) => {

  let headerOptions = {
    'authorization': `Bearer ${localStorage.getItem("cart-session")}`,
    'Accept': 'application/json, text/plain, */*',
    'Content-Type': 'application/json'
  };

  try {
    const responseData = await axios(
      Object.assign(options, { headers: headerOptions })
    );
    if (options.successMsg) {
      toast.success(options.successMsg);
    }

    return responseData;
  } catch (error) {
    if (options.errorMessage) {
      toast.error(options.errorMessage,{autoClose:1200});
    } else if (!options.hidePopup) {
      toast.error(error?.response?.data?.message || "Something went wrong",{autoClose:1200});
    }
    if (error.response) {
      return error.response;
    }
    else {
      return error;
    }
  }
}

export default CallApiWithoutAuth;
