
import "./addressModal.css"
import React, { useRef, useState, useEffect } from "react"
import UseOutsideClick from "../detectOutsideClick/useOutSideClick"
import { addUserAddress, editUserAddress, defaultUserAddress, emptyCart, pincodeDeliveryEstimate } from "../../../utils/apiUrlsSetup"
import { toast } from "react-toastify"

const AddressModal = ({ openAddAddressModal, setOpenAddAddressModal, oldAddressData, getUserAddressList, clearUserAddress, showHeaderAddress, setShowHeaderAddress, headerAddressList, cartListData, getCartListData, getCoordinates,checkDeliveryAvailablity }) => {
    const [updatePin, setUpdatedPin]  = useState();
    let authToken = localStorage.getItem("authToken") || "";
    const pincode = localStorage.getItem("pincode") || "";
    const [userCategory, setUserCategory] = useState('Mr')
    const [userAddressData, setUserAddressData] = useState({})
    const [pinCode, setPinCode] = useState('');
    const [showAddressChange, setShowAddressChange] = useState(false)
    const [addressId, setAddressId] = useState('')
    const [isLocationPermissionAllowed, setPermissionAllowed] = useState(false);

    useEffect(()=>{
        window?.navigator?.permissions?.query({name:'geolocation'}).then((locationIsAllowed)=>{
          setPermissionAllowed(()=>locationIsAllowed?.state === 'granted')
        //   window.location.reload(true);
        })
      },[])

    useEffect(()=>{
        if(pincode){
            setPinCode(pincode)
        }
    },[pincode])

    useEffect(() => {
        if (oldAddressData.id) {
            const copyOldAddressData = { ...oldAddressData };
            const name = copyOldAddressData.name.split(" ")
            if (name[0] === "Mr" || name[0] === "Mrs" || name[0] === "Miss") {
                copyOldAddressData.name = name.slice(1).join(" ");
                setUserCategory(name[0])
            }
            setUserAddressData(copyOldAddressData)
        }
        else {
            setUserAddressData({
                name: '',
                mobile_number: '',
                landmark: '',
                address: '',
                address_type: '',
                pincode: '',
            })
        }
    }, [oldAddressData])

    const ref = useRef()
    UseOutsideClick(ref, () => {
        if (openAddAddressModal) {
            setOpenAddAddressModal(false)
            clearUserAddress();
            setUserAddressData({
                name: '',
                mobile_number: '',
                landmark: '',
                address: '',
                address_type: '',
                pincode: '',
            });
        }
        else if (showHeaderAddress) {
            setShowHeaderAddress(false)
        }
        else if (!authToken && !pincode){
            setShowHeaderAddress(true)
        }

    })

    const setUserAddress = (name, e) => {
        const copyUserAddressData = { ...userAddressData };
        if (name === "is_default") {
            copyUserAddressData[name] = e.target.checked ? 1 : 0;
        }
        else {
            copyUserAddressData[name] = e.target.value
        }

        setUserAddressData(copyUserAddressData)
    }

    const AddUserAddress = async () => {
        const copyAddressData = { ...userAddressData }
        copyAddressData.name = `${userCategory} ${copyAddressData.name}`;

        if (userAddressData.id) {

            let newEditAddress = { ...userAddressData };

            newEditAddress.address_id = userAddressData.id;
            newEditAddress.name = userCategory + " " + newEditAddress.name

            const response = await editUserAddress(newEditAddress)
            if (response.ErrorCode === 0) {
                setOpenAddAddressModal(false)
                toast.success(response.Response)
                getUserAddressList();
            }
        }
        else {
            const response = await addUserAddress(copyAddressData)
            if (response.ErrorCode === 0) {
                setOpenAddAddressModal(false)
                toast.success(response.Response)
                getUserAddressList();

            }
        }

        setUserAddressData({})
        clearUserAddress();

    }

    const makeDefaultAddress = async () => {
        
        const formData = new FormData()
        formData.append("address_id", addressId)
        const response = await defaultUserAddress(formData)
        if (response.ErrorCode === 0) {
            getUserAddressList(true);
            cartEmpty()
            setShowHeaderAddress(false)
            checkDeliveryAvailablity && checkDeliveryAvailablity(updatePin)
        }
       
    }

    const cartEmpty = async () => {
        const response = await emptyCart({})
        if (response.ErrorCode === 0) {
            getCartListData()
        }
    }

    const savePincode = () => {
        localStorage.setItem("pincode", pinCode)
        localStorage.removeItem("address")
        setShowHeaderAddress(false)
        checkDeliveryAvailablity && checkDeliveryAvailablity(pinCode)
    }

    const handleAddressChangeConfirmation = (id) =>{
        setAddressId(id)
        if(cartListData){
            setShowAddressChange(true)
        }
        else{
            makeDefaultAddress()
        }
    }
    function refreshPage() {
        window.location.reload(true);
      }

    return (
        <>

            {showHeaderAddress && authToken ? <div className={`modal fade address_modal ${showHeaderAddress ? "show" : ''}`} style={{ background: showHeaderAddress ? 'rgba(0,0,0,0.5)' : '', display: showHeaderAddress && !showAddressChange ? "block" : "none" }} >
                <div className="modal-dialog modal-lg modal_custom_width header_address_alignment" ref={ref}>
                    <div className="modal-content">
                        <div className="modal-header d-block p-2 border-bottom-0 d-flex">
                            <h5 className="modal-title fs-5 font_sub_heading" id="staticBackdropLabel">Select Delivery Address</h5>
                            <button 
                            type="button" 
                            className="btn-close justify-content-end" 
                            data-bs-dismiss="modal"
                            onClick={()=>{setShowHeaderAddress(false)}}
                            ></button>
                        </div>
                        <div className="modal-body p-0 pb-2">
                            {headerAddressList && headerAddressList.length ? headerAddressList.map((value, index) => {
                                return <div className={`address_tabs border-bottom ${index === 0 ? "border-top" : ''}`} key={index} onClick={() => { 
                                    setUpdatedPin(value?.pincode)
                                    handleAddressChangeConfirmation(value.id);                              
                                      }}>
                                    <p className="m-0 text-secondary pe-4 lh-sm font-lighter py-2 px-3 font_paragraph" >
                                        {value.name}{" - "}{value.landmark}{" "}{value.address}{" "}{value.city ? value.city : ''}{" "}{value.pincode}{" India."}
                                    </p>
                                </div>
                            }) : null}

                            
                        </div>
                        <div className="d-flex justify-content-between p-2">
                        <button
                                type="button"
                                className={`bg_green text-white px-1 py-2 mt-3 mb-2 me-3 rounded w-8/12`}
                                onClick={getCoordinates}
                            >
                                Detect My Location
                            </button>
                        <button 
                            type="button" 
                            className="bg_green text-white px-1 py-2 mt-3 mb-2 me-3 rounded w-8/12"
                            onClick={()=>{
                                setOpenAddAddressModal(true)
                            }}  
                        >
                         Add Address
                        </button>
                        </div>
                        {
                                !isLocationPermissionAllowed &&
                                <p className="text-danger px-2 ">
                                    Location permissions is not provided
                                </p>
                            }
                    </div>
                </div>
            </div>
                :

                <div className={`modal fade address_modal ${showHeaderAddress ? "show" : ''}`} data-bs-backdrop="static" style={{ background: !authToken ? 'rgba(0,0,0,0.5)' : '', display: !authToken ? "block" : "none" }} ref={ref}>
                    <div className="modal-dialog modal-lg modal_custom_width header_pincode_alignment" ref={ref}>
                        <div className="modal-content px-4 py-2">
                        <div className="modal-header d-block p-2 border-bottom-0 d-flex">
                            <h5 className="modal-title fs-5 font_sub_heading" id="staticBackdropLabel">Enter Delivery Pincode</h5>
                                <button 
                                    type="button" 
                                    className="btn-close justify-content-end" 
                                    data-bs-dismiss="modal"
                                    onClick={()=>{setShowHeaderAddress(false)}}
                                ></button>                            
                            </div>
                            <div className="modal-body p-0 pb-2">
                                <input type="number" className="form-control" maxLength={6} name="pincode" value={pinCode} onChange={(e) => { setPinCode(e.target.value) }} />
                            </div>
                            <div className="d-flex justify-content-end">
                            <button
                                type="button"
                                className={`bg_green text-white px-2 py-2 mt-3 mb-2 me-3 rounded w-8/12`}
                                onClick={getCoordinates}
                            >
                                Detect My Location
                            </button>
                            <button
                                type="button"
                                className={`${pinCode.length === 6 ? "bg_green" : "bg_disable" } text-white px-2 py-2 mt-3 mb-2 rounded w-4/12`}
                                onClick={savePincode}
                                disabled={pinCode.length === 6 ? false : true }
                            >
                                Save
                            </button>
                            </div>
                            {
                                !isLocationPermissionAllowed &&
                                <p className="text-danger ">
                                    Location permissions is not provided
                                </p>
                            }
                        </div>
                    </div>
                </div>}

                {showAddressChange && 
                    <div className={`modal fade address_modal ${showAddressChange ? "show" : ''}`} data-bs-backdrop="static" style={{ background: showAddressChange ? 'rgba(0,0,0,0.5)' : '', display: showAddressChange ? "block" : "none" }}>
                    <div className="modal-dialog modal-lg modal_custom_width">
                        <div className="modal-content px-4 py-2 pb-4 address_change_confirmation_modal">
                            <div className="modal-header d-block py-2 px-0 border-bottom-0">
                                <h5 className="modal-title" id="staticBackdropLabel font_sub_heading">Remove Cart Items ?</h5>
                            </div>
                            <div className="modal-body p-0 pb-2">
                                <p className="m-0 text-center font_paragraph py-3">Your cart contains items from previous address. Do you want to discard the selection and use this address ?</p>
                            </div>
                                <div className="d-flex justify-content-between">
                                    <button 
                                        type="button" 
                                        className="py-2 px-4 bg_disable text-white ms-5 rounded"
                                        onClick={(e)=>{
                                            setShowAddressChange(false)}}
                                    >
                                        No
                                    </button>
                                    <button 
                                        type="button" 
                                        className="py-2 px-4 bg_green text-white me-5 rounded"
                                        onClick={makeDefaultAddress}
                                    >
                                        Yes
                                    </button>
                                </div>
                            
                        </div>
                    </div>
                </div>}

            {openAddAddressModal ? <div className={`modal fade address_modal ${openAddAddressModal ? "show" : ''}`} style={{ background: openAddAddressModal ? 'rgba(0,0,0,0.5)' : '', display: openAddAddressModal ? "block" : "none" }} >
                <div className="modal-dialog modal-dialog-centered modal-lg modal_custom_width" ref={ref}>
                    <div className="modal-content">
                        <div className="modal-header d-block p-4 border-bottom-0">
                            <h5 className="modal-title text-center font_sub_heading" id="staticBackdropLabel">Add New Delivery Address</h5>
                            <p className="m-0 text-center text-secondary font_paragraph">Please enter the accurate address, it will help us to serve you better</p>
                        </div>
                            <form onSubmit={AddUserAddress}>
                        <div className="modal-body p-0">
                            <div className="address_other_details px-4 mt-3">
                                <div class="mb-2">
                                    <label htmlFor="#" className="form-label font_13 text-secondary mb-1">Name</label>
                                    <div className="name_input_group d-flex">
                                        <select class="form-select font_paragraph" id="specificSizeSelect" value={userCategory} onChange={(e) => { setUserCategory(e.target.value) }} >
                                            <option className="font_paragraph" value="" disabled>Title</option>
                                            <option className="font_paragraph" value="Mr">Mr</option>
                                            <option className="font_paragraph" value="Mrs">Mrs</option>
                                            <option className="font_paragraph" value="Miss">Miss</option>
                                        </select>
                                        <input type="text" className="form-control font_paragraph" placeholder="First & LastName" name="name" value={userAddressData.name} onChange={(e) => setUserAddress('name', e)} />
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="#" className="form-label font_13 text-secondary mb-1">Mobile No.</label>
                                    <input
                                      type="tel"
                                      className="form-control font_paragraph"
                                      placeholder=""
                                      name="mobile_number"
                                      value={userAddressData.mobile_number}
                                      onChange={(e) => {
                                        setUserAddress("mobile_number", e);
                                      }}
                                      pattern="^\d{10}$"
                                      title="Enter 10 digit number."
                                    />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="#" className="form-label font_13 text-secondary mb-1">Flat / House / Office No.</label>
                                    <input type="text" className="form-control font_paragraph" placeholder="" name="landmark" value={userAddressData.landmark} onChange={(e) => setUserAddress('landmark', e)} />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="#" className="form-label font_13 text-secondary mb-1">Street / Society / Office Name</label>
                                    <input type="text" className="form-control font_paragraph" placeholder="" name="address" value={userAddressData.address} onChange={(e) => setUserAddress('address', e)} />
                                </div>
                                <div class="mb-2">
                                    <label htmlFor="#" className="form-label font_13 text-secondary mb-1">Pincode</label>
                                    <input type="number" className="form-control font_paragraph" placeholder="" name="pincode" value={userAddressData.pincode} onChange={(e) => setUserAddress('pincode', e)} />
                                </div>
                                <div className="d-flex">
                                    <div className="form-check mx-2">
                                        <input className="form-check-input" type="radio" name="address_type" id="flexRadioDefault2" value="Home" checked={userAddressData.address_type === "Home" ? true : false} onChange={(e) => setUserAddress('address_type', e)} />
                                        <label className="form-check-label font_13 text-secondary mb-1" for="flexRadioDefault2">
                                            Home
                                        </label>
                                    </div>
                                    <div className="form-check mx-2">
                                        <input className="form-check-input" type="radio" name="address_type" id="flexRadioDefault3" value="Office" checked={userAddressData.address_type === "Office" ? true : false} onChange={(e) => setUserAddress('address_type', e)} />
                                        <label className="form-check-label font_13 text-secondary mb-1" for="flexRadioDefault3">
                                            Office
                                        </label>
                                    </div>
                                    <div className="form-check mx-2">
                                        <input className="form-check-input" type="radio" name="address_type" id="flexRadioDefault1" value="Other" checked={userAddressData.address_type === "Other" ? true : false} onChange={(e) => setUserAddress('address_type', e)} />
                                        <label className="form-check-label font_13 text-secondary mb-1" for="flexRadioDefault1">
                                            Other
                                        </label>
                                    </div>
                                </div>
                                <div class="mb-2 d-flex">
                                    <input
                                        type="checkbox"
                                        name="is_default"
                                        id=""
                                        className="form-check cursor-pointer ms-2"
                                        onChange={(e) => setUserAddress('is_default', e)}
                                        checked={userAddressData.is_default ? userAddressData.is_default === 0 ? false : true : false}
                                    />
                                    <label htmlFor="#" className="default_checkbox_title form-label font_13 text-secondary ms-2 mb-0">Make it default address.</label>
                                </div>
                                <div className="modal-footer p-0 d-flex justify-content-start border-top-0 pt-0 my-2 mb-4">
                                    <button type="submit"
                                        className="btn continue_btn text-white font_basic"
                                    >
                                        Continue
                                    </button>
                                    <button type="button"
                                        className="btn border text-secondary font_basic"
                                        onClick={() => {
                                            clearUserAddress();
                                            setUserAddressData({
                                                name: '',
                                                mobile_number: '',
                                                landmark: '',
                                                address: '',
                                                address_type: '',
                                                pincode: '',
                                            });
                                            setOpenAddAddressModal(false)
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
                : null}
        </>
    )
}

export default AddressModal