import Superfast_Delivery from "../../../assests/Free-Shipping.png"
import Best_Prices_Offers from "../../../assests/BPG.png"
import Wide_Assortment from "../../../assests/ER.png"
import Easy_Returns_Refund from "../../../assests/Return.png"

const FooterTopCard = () =>{

    const cardData = [
        {
            url:Superfast_Delivery,
            heading: "Lightning-Fast Delivery",
            description: "Get any product delivered to your doorstep with our fast delivery partners .",
        },
        {
            url:Best_Prices_Offers,
            heading: "Lowest Prices & Best Offers",
            description: "Genuine quality products at the most economical prices along with offers and reward points.",
        },
        {
            url:Wide_Assortment,
            heading: "Extensive Range",
            description: "Choose products from different kitchen appliance categories in a user-friendly interface.",
        },
        {
            url:Easy_Returns_Refund,
            heading: "Hassle-Free Return/Refund",
            description: "In case of any issue, easy return or refund for a pleasant after-purchase experience.",
        },
    ]

    return (
        <>
            {cardData.map((value, index)=>{
                return <div className="col-12 col-md-6 col-lg-3 footer_card_height mt-5" key={index}>
                <div className="p-3 border">
              <div className="d-flex justify-content-center">
            <div className=" footer_features_icon">
              <div
                height="100%"
                width="100%"
                className="sc-hKMtZM bRIJNO  iNeJeA"
              >
                 
                <img
                  alt="Superfast Delivery"
                  src={value.url}
                  loading="lazy"
                  className="icon-image cUAuNp"
                />
              </div>
            </div>
            </div>
            <div
              size="14"
              color="#000000"
              className=" iZMVhO font_basic"
            >
              {value.heading}
            </div>
            <div
              size="12"
              color="#666666"
              className="text-center font_paragraph lh-sm"
            >
              {value.description}
            </div>
          </div>
          </div>

            })  }

            <style jsx>
                {`
                
                //  .footer_card_height{
                //     height:250px;
                //   } 

                `}
            </style>
        </>
    )
}

export default FooterTopCard ;