import React,{useEffect, useState} from "react";
import {
    pagesList,
    socialList
  } from "../../../utils/apiUrlsSetup";
  
import { Link } from "react-router-dom";

const FooterUsefulLinks = () => {

    const [linksData1, setCategoryListData] = useState([])

    const authToken = localStorage.getItem("authToken") || '' ;
    const pincode = localStorage.getItem("pincode") || '' ;

    const getCategoryDataList = async () => {
        let response = await pagesList({"pincode":pincode})
        if ( response && response.ErrorCode === 0 && response.Response!==null) {
            setCategoryListData(response.Response)
        }
    }

    useEffect(()=>{
        getCategoryDataList()
    },[])
    
        
    // let linksData = await pagesList({"pincode":pincode})
    // let socialData = await socialList({"pincode":pincode, page: 1})
    /*
    const linksData = [
        {
            link:"About Us",
            url: "/about_us"
        },
        {
            link:"Privacy Policy",
            url: "/privacy_policy"
        },
        {
            link:"Shipping Policy",
            url: "/shipping_policy"
        },
        {
            link:"Refund & Replacment",
            url: "/refund"
        },
        {
            link:"Term & Condition",
            url: "/term_&_condition"
        }, 
    ] */


    return (
        <>
            <div className="ms-md-3">
                <div className="FooterLinks__ListHeading-sc-12rhzht-1 aonOx font_heading d-inline-block">
                    Useful Links
                </div>
                <div className="d-flex">
                    
                        <ul type="col" className={`FooterLinks__List-sc-12rhzht-2 fBgyNM`}>
                            <li className="FooterLinks__ListItem-sc-12rhzht-3 BvTiN">
                                <ul type="col" className="FooterLinks__List-sc-12rhzht-2 jqmuYk" >
                                    {linksData1.map((link, index) => {
                                        return <li className="FooterLinks__ListItem-sc-12rhzht-3 BvTiN font_paragraph" key={index}>
                                            <a
                                                href={"/pages/" + link.slug_name}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="FooterLinkshelpers__FooterLink-sc-f4rm2u-0 cmyjwV"
                                            >
                                                {link.name}
                                            </a>

                                            
                                        </li>
                                    })}
                                        <li className="FooterLinks__ListItem-sc-12rhzht-3 BvTiN font_paragraph">
                                            <a
                                                href={"/contact-us"}
                                                rel="noopener noreferrer"
                                                className="FooterLinkshelpers__FooterLink-sc-f4rm2u-0 cmyjwV"
                                            >
                                                Contact Us
                                            </a>

                                            
                                        </li>

                                </ul>
                            </li>
                        </ul>
                    
                </div>
            </div>
            <style jsx>
                {`
                
                .user_link_width{
                    width: 207px;
                }

                @media only screen and (max-width: 900px){
                    .user_link_width{
                        width: 100px;
                    }
                }

                `}
            </style>
        </>
    )
}

export default FooterUsefulLinks;