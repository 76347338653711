import React, { useState, useEffect } from "react";
import { Footer, Header } from "../shared-components";
import "./orderPage.css"
import Loader from "../shared-components/loader/loader";
import CommonSideTabs from "../shared-components/commonSideTabs/commonSideTabs"
import OrderCard from "../shared-components/orderCard/orderCard"
import {
    orderOpen,
    orderDetails,
    completedOrder,
    returnReplacement,
    cartItemsCount,
} from "../../utils/apiUrlsSetup"
import OrderDetailTabs from "../shared-components/orderDetailTabs/orderDetailTabs"
import ViewOrderDetails from "../shared-components/viewOrderDetails/viewOrderDetails"
import { toast } from "react-toastify";

const OrderPage = () => {

    const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {}
    const authToken = localStorage.getItem("authToken") || "" ;

    const [selectedTab, setSelectedTab] = useState("open")
    const [showLoader, setShowLoader] = useState(false)

    const [openOrderList, setOpenOrderList] = useState([])
    const [completedOrderList, setCompletedOrderList] = useState([])

    const [showViewDetails, setShowViewDetails] = useState(false)
    const [viewDetailsData, setViewDetailsData] = useState([])

    const [returnOrderList, setReturnOrderList] = useState([])
    const [replaceOrderList, setReplaceOrderList] = useState([])

    const [badgeCount, setBadgeCount] = useState(0)
    const [badgePrice, setBadgePrice] = useState(0)
    const [reorderOpenCart, setReorderOpenCart] = useState(false)
    const [successMessagePopup, setSuccessMessagePopup] = useState(false);


    useEffect(() => {
        if (selectedTab === "open") {
            openOrdersList()
        }
    }, [])
    
    const getCartBadgeCount= async()=>{
        const response = await cartItemsCount({});
        if(response.ErrorCode === 0){
            setBadgeCount(response?.Data?.total_count)
            setBadgePrice(response?.Data?.total_price)
        }
    }

    const openOrdersList = async () => {
        setShowLoader(true)
        const data = await orderOpen({})
        if (data?.ErrorCode === 0) {
            setShowLoader(false)
            setOpenOrderList(data?.Response);
            setShowViewDetails(false)
        }
    }

    const getReturnList = async() =>{
        setShowLoader(true)
        const formData = new FormData()
        formData.append("return_type", 1 )
        const data = await returnReplacement(formData)

        if(data.ErrorCode === 0){
            setShowLoader(false)
            setReturnOrderList(data.Response?.filter((r=>r?.return_type===1)))
        }
    }

    const getReplacementList = async() =>{
        setShowLoader(true)
        const formData = new FormData()
        formData.append("return_type", 2 )
        const data = await returnReplacement(formData)

        if(data.ErrorCode === 0){
            setShowLoader(false)
            setReplaceOrderList(data.Response?.filter(r=>r?.return_type===2))            
        }
    }

    const getOrderDetails = async (id) => {
        setShowLoader(true)
        const formData = new FormData()
        formData.append("order_id", id)
        const data = await orderDetails(formData);

        if (data.ErrorCode === 0) {
            setShowLoader(false)
            setViewDetailsData(data.Response)
            setShowViewDetails(true)
        }else if(data.ErrorCode === -101){
            setShowLoader(false)
            toast.error("Data Is Not Available")
        }
    }

    const getCompletedOrderList = async() =>{
        setShowLoader(true)
        const response = await completedOrder({})
        if(response.ErrorCode === 0){
            setShowLoader(false)
            // setCompletedOrderList(response.Response?.filter(r=>r?.current_status==='Delivered'))
            setCompletedOrderList(response.Response?.filter(r=>["Delivered","Completed","Rejected","Cancelled"].includes(r?.current_status) ) )
            setShowViewDetails(false)
        }
    }

    const reloadOrders = (orderType)=>{

        if(orderType === "open"){
            setSelectedTab("open")
            openOrdersList()
        }
        else if(orderType === "completed"){
            setSelectedTab("completed")
            getCompletedOrderList()
        }
        else if(orderType === "return"){
            setSelectedTab("return")
            getReturnList()
        }
        else if(orderType === "replacement"){
            setSelectedTab("replacement")
            getReplacementList()
        }
    }

    const handleError = (error) => {
        toast.error(error ?? 'Something Went Wrong', { autoClose: 1200 })
    }
    // console.log("reorderOpenCart",  reorderOpenCart)

    return (
        <>
            <Header
                noShadow
                userDetails={userDetails}
                badgeCount={badgeCount}
                badgePrice={badgePrice}
                reorderOpenCart={reorderOpenCart}
                setReorderOpenCart={setReorderOpenCart}
            />
            <div className="row m-0 px-3 p-lg-0">
                <div className="col-12 col-lg-2 p-0"></div>
                <div className="col-12 col-lg-8 profile_page_inner p-0">
                    <div className="row m-0">
                        <div className="col-3 p-0 left_addresss_bar d-none d-md-block">
                            <CommonSideTabs
                                activeTab={"order"}
                                userDetails={userDetails}
                            />
                        </div>
                        <div className="col-12 col-md-9 py-4 p-0">

                            <OrderDetailTabs
                                selectedTab={selectedTab}
                                reloadOrders={(data)=>{reloadOrders(data)}}
                            />

                            <div className="order_switchable_container mt-3">

                                {showViewDetails && selectedTab === "open" ?
                                    <ViewOrderDetails
                                        viewDetailsData={viewDetailsData}
                                        reloadOrders={(data)=>{reloadOrders(data)}}
                                        activeTab={"open"}
                                    />
                                    : null }

                                {selectedTab === "open" && !showViewDetails ?
                                    <OrderCard
                                        orderList={openOrderList}
                                        setShowViewDetails={setShowViewDetails}
                                        getOrderDetails={(id) => { getOrderDetails(id) }}
                                        setReorderOpenCart={setReorderOpenCart}
                                        getCartBadgeCount={getCartBadgeCount}
                                    />
                                    : null}

                                {showViewDetails && selectedTab === "completed" ?
                                    <ViewOrderDetails
                                        viewDetailsData={viewDetailsData}
                                        activeTab={"completed"}
                                    /> 
                                    : null}

                                {!showViewDetails && selectedTab === "completed" ? 
                                <OrderCard
                                    orderList={ !showViewDetails ? completedOrderList : null}
                                    setShowViewDetails={setShowViewDetails}
                                    getOrderDetails={(id) => { getOrderDetails(id) }}
                                    setReorderOpenCart={setReorderOpenCart}
                                    getCartBadgeCount={getCartBadgeCount}
                                    handleError = {handleError}
                                /> : null}

                                {selectedTab === "return" && returnOrderList ?
                                <OrderCard
                                    orderList={returnOrderList}
                                    setShowViewDetails={setShowViewDetails}
                                    getOrderDetails={(id) => { getOrderDetails(id) }}
                                /> : null}

                                {selectedTab === "replacement" && replaceOrderList ?
                                    <OrderCard
                                        orderList={replaceOrderList}
                                        setShowViewDetails={setShowViewDetails}
                                        getOrderDetails={(id) => { getOrderDetails(id) }}
                                    /> : null}
                                
                            </div>

                            {showLoader ? <Loader bottom="50%" right="47%" /> : null}

                        </div>
                    </div>
                </div>
            </div>
            <div className="px-3">
            <Footer />
            </div>
        </>
    )
}

export default OrderPage;
