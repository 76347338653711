import React from "react";
import { useNavigate } from "react-router-dom";
import "./returnItem.css"

const ReturnItem = ({ viewDetailsData }) => {

    const navigate = useNavigate();
    
    return (
        <div className="return_item px-3">
            <div className="row m-0 border-bottom pb-3">
                <div className="col-6">
                    <p className="m-0 font_500">Order Id</p>
                    <p className="m-0 font_500">Order Date</p>
                    <p className="m-0 font_500">Order Status</p>
                    <p className="m-0 font_500">Paid Amount</p>
                </div>
                <div className="col-6 text-end">
                    <p className="m-0 font_500">{viewDetailsData.order_number}</p>
                    <p className="m-0 font_500">{viewDetailsData.order_date}</p>
                    <p className="m-0 font_500">{viewDetailsData.order_status}</p>
                    <p className="m-0 font_500">₹{Math.floor(viewDetailsData.total)}</p>
                </div>
            </div>
            <div className="row m-0 my-3">
                <p className="m-0 font_500 fs-4 px-0 px-md-2">Item Details</p>
                {viewDetailsData && viewDetailsData.items.map((data, index) => {
                    return <div className="row m-0 mt-2 pb-4 p-0 border-bottom" key={index}>
                        <div 
                            className="col-3 col-md-2 pe-md-0 ps-0 ps-md-2 cursor-pointer"
                            onClick={()=>{navigate(`/product/${data.product_name}/${data.id}`)}}
                        >
                            <div className="order_item_image border rounded p-2 d-flex justify-content-center">
                                <img src={data.product_image} alt="" />
                            </div>
                        </div>
                        <div className="col-9 col-md-10 ps-md-0 pe-0 pe-md-2">
                            <div className="item_details">
                                <p className="m-0">{data.product_name}</p>
                                <div className="d-flex justify-content-between item_details_bottom">
                                    <p className="m-0 item_quantity text-secondary">{`qty - ${data.quantity}  ₹${Math.floor(data.offer_price)}`}</p>
                                    <button 
                                        type="button"
                                        className="px-3 py-2 bg_green text-white rounded"
                                        onClick={()=>{navigate(`/account/order/return/${viewDetailsData.order_number}`)}}
                                    >
                                        Return
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default ReturnItem;