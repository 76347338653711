import React,{useState} from "react"
import "./viewOrderDetails.css"
import {Link, useNavigate} from "react-router-dom"
import ReturnItem from "../returnItem/returnItem"
import {cancelOrder} from "../../../utils/apiUrlsSetup"
import { toast } from "react-toastify"

const ViewOrderDetails = ({ viewDetailsData, activeTab, reloadOrders }) => {

    const navigate = useNavigate()
    const [showItemReturn, setShowItemReturn] = useState(false);
    const [cancelOrderPopup, setCancelOrderPopup] = useState(false);
    const [reasonMsg, setReasonMsg] = useState('')

    const cancelSelectedOrder = async() =>{
        const formData = new FormData()
        formData.append("order_id", viewDetailsData.order_number)
        formData.append("cancel_reason", reasonMsg)
        const response = await cancelOrder(formData)
        if(response.ErrorCode === 0){
            setCancelOrderPopup(false)
            toast.success('Order Cancelled Successfully')
            reloadOrders('open')
        }else{
            setCancelOrderPopup(false)
            toast.error(response?.ErrorCode)

        }
    }

    return (
        <>
        {activeTab === "completed" && showItemReturn === true ? 
         <ReturnItem
            viewDetailsData={viewDetailsData} 
        />
        :
            <div className="order_summary  mt-2 px-md-3 ">
                <div className="row m-0 border-bottom">
                    <h3 className="font_heading d-flex justify-content-between">
                        <span>Order Summary</span>
                        <span>#{viewDetailsData.order_number}</span>
                    </h3>
                    <p className="m-0 text-secondary font_paragraph">Expecting Delivery at {viewDetailsData.estimate_delivery_time}</p>

                    {activeTab === "completed" ?
                    <p className="m-0 d-flex justify-content-between text_green font_basic">
                        {/* <p className="m-0 cursor-pointer">Download summary <i class="fa-solid fa-download"></i></p> */}
                        {viewDetailsData && !["Rejected","Cancelled"].includes(viewDetailsData.order_status) && <a href={`${viewDetailsData.download_invoice}`} className="cursor-pointer" target="_blank">Download summary <i class="fa-solid fa-download"></i></a>}
                        {viewDetailsData && viewDetailsData.order_status !== "Cancelled"  && viewDetailsData.return_window===1  && <span 
                            className="cursor-pointer"
                            onClick={()=>{setShowItemReturn(true)}}
                        >
                        Return & Replacement
                        </span>}
                    </p> 
                    :
                    <p className="m-0 d-flex justify-content-end font_basic cancel_order_text mb-2">
                        {viewDetailsData && viewDetailsData.order_status === "New"  && <span 
                            className="cursor-pointer"
                            onClick={()=>{setCancelOrderPopup(true)}}
                        >
                            Cancel Order
                        </span>}
                    </p>}

                </div>
                <div className="row m-0 border_bottom_large pb-3">
                    <p className="m-0 font_sub_heading fs-5 my-2">Order Details</p>
                    <div className="item_more_details my-2 col-3">
                    <p className="m-0 text-secondary font_basic">Order ID:</p>
                        <p className="m-0 font_basic">{viewDetailsData.order_number}</p>
                    </div>
                    <div className="item_more_details my-2 col-3">
                        <p className={`m-0 text-secondary font_basic`}>Status:</p>
                        <p className={`m-0 font_basic ${viewDetailsData.order_status === "Shipped" || viewDetailsData.order_status === "New"  ? "text-success" : ''}`}>{viewDetailsData.order_status}</p>
                    </div>
                    <div className="item_more_details my-2 col-3">
                        <p className="m-0 text-secondary font_basic">Payment Type:</p>
                        <p className="m-0 font_basic">{viewDetailsData.payment_mode}</p>
                    </div>
                    <div className="item_more_details mt-2 col-3">
                        <p className="m-0 text-secondary font_basic">Ordered At:</p>
                        <p className="m-0 font_basic">{viewDetailsData.order_created_at}</p>
                    </div>
                    <div className="item_more_details my-2">
                        <p className="m-0 text-secondary font_basic">Deliver To:</p>
                        <p className="m-0 font_basic">{viewDetailsData.deliver_to}</p>
                    </div>
                </div>
                <div className="row m-0 border_bottom_large pb-3">
                    <p className="m-0 font_sub_heading my-2">{viewDetailsData && viewDetailsData.items.length} Item in this order</p>
                    {viewDetailsData.items.length && viewDetailsData.items.map((data, index) => {
                        return <div className="row m-0 pb-2" key={index}>

                            <div className="col-4 col-md-2 pe-lg-0 cursor-pointer">
                                <div 
                                    className="order_item_image border rounded p-2 d-flex justify-content-center"
                                    onClick={()=>{navigate(`/product/${data.product_name}/${data.id}`)}}
                                    >
                                    <img src={data.product_image} alt="" />
                                </div>
                            </div>
                            <div className="col-8 col-md-10 ps-lg-0">
                                <div className="item_details">
                                    <p className="m-0 font_basic text-secondary">{data.product_name}</p>
                                    <div className="d-flex justify-content-between item_details_bottom">
                                        <p className="m-0 item_quantity text-secondary font_13 font_paragraph">qty - {data.quantity}</p>
                                        <p className="m-0 item_price font_basic"> ₹{Math.floor(data.offer_price)}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
                <div className="row m-0">
                    <p className="m-0 fs-4 my-2 font_sub_heading">Bill Details</p>
                    <div className="col-6">
                        <p className="m-0 font_basic">Sub Total (incl. taxes)</p>
                    </div>
                    <div className="col-6 text-end">
                        <p className="m-0 font_basic">₹{viewDetailsData.sub_total ? Math.floor(viewDetailsData.sub_total) : 0}</p>
                    </div>
                </div>
                {/* <div className="row m-0">
                    <div className="col-6">
                        <p className="m-0 font_basic">Taxes</p>
                    </div>
                    <div className="col-6 text-end">
                        <span className="d-flex justify-content-end">
                            <p className="m-0 font_basic">Tax Rate</p>
                            <p className="m-0 font_basic ms-3"> Tax Amount</p>
                        </span>
                        <span className="d-flex justify-content-end font_paragraph">
                            <p className="m-0 me-3">{viewDetailsData.tax["0"].title} ({viewDetailsData.tax["0"].rate}%)</p>
                            <p className="m-0 ms-4 d-flex">₹ {Math.floor(viewDetailsData.tax["0"].tax_amount)}</p>
                        </span>
                    </div>
                </div> */}
                <div className="row m-0 border_bottom_large pb-3">
                    <div className="col-6">
                        {/* <p className="m-0 font_basic">Total Tax</p> */}
                        <p className="m-0 font_basic">Delivery Charges</p>
                        <p className="m-0 font_basic fs-5">Bill Total</p>
                    </div>
                    <div className="col-6 text-end">
                        {/* <p className="m-0 font_basic">₹{Math.floor(viewDetailsData.tax.total_tax)}</p> */}
                        <p className="m-0 font_basic">+₹{viewDetailsData.deliver_charge ? Math.floor(viewDetailsData.deliver_charge) : 0 }</p>
                        <p className="m-0 font_basic fs-5">₹{Math.floor(viewDetailsData.total)}</p>
                    </div>
                </div>
                <div className="row m-0 my-3">
                    <div className="contact_support d-flex">
                        <i class="fa-solid fa-headset fs-2 mt-2"></i>
                        {/* <img src={productImg} alt="" className="rounded-circle" height="80px" width="80px" /> */}
                        <div className="ms-3 cursor-pointer">
                            <p className="m-0 fw-bold fs-5 font_heading">Need help with your order ?</p>
                            <p className="m-0 text-secondary font_13 font_paragraph">Support is always available</p>
                        </div>
                    </div>
                </div>
                <div className="row m-0 my-3">
                    <div className="contact_support d-flex">
                        <i class="fa-solid fa-message fs-2 mt-2"></i>
                        {/* <img src={productImg} alt="" className="rounded-circle" height="80px" width="80px" /> */}
                        <div className="ms-3 cursor-pointer">
                            <p className="m-0 fw-bold fs-5 text_green font_heading"><a href="https://wa.me/919899339093" target="_blank">Chat with support executive</a></p>
                            <p className="m-0 text-secondary font_13 font_paragraph">About any issues related to your order</p>
                        </div>
                    </div>
                </div>
            </div>}


            {cancelOrderPopup ? <div className={`modal fade address_modal ${cancelOrderPopup ? "show" : ''}`} style={{ background: cancelOrderPopup ? 'rgba(0,0,0,0.5)' : '', display: cancelOrderPopup ? "block" : "none" }} >
                    <div className="modal-dialog modal-dialog-centered modal-lg modal_custom_width ">
                        <div className="modal-content p-4">
                            <div className="modal-header d-flex justify-content-between py-2 px-0 border-bottom-0">
                                <h5 className="modal-title font_sub_heading" id="staticBackdropLabel">Why do you want to return this product ?</h5>
                                <button 
                                    type="button" 
                                    className="btn-close" 
                                    data-bs-dismiss="modal"
                                    onClick={()=>{setCancelOrderPopup(false)}}
                                    ></button>
                            </div>
                            <div className="modal-body p-0 pb-2">
                                <textarea 
                                    name="reasonToReturn" 
                                    id="reasonToReturn"  
                                    rows="3" 
                                    className="w-100 mt-2 p-2 reason_to_return_modal_textarea border rounded font_paragraph" 
                                    onChange={(e)=>{setReasonMsg(e.target.value)}}
                                    ></textarea>
                            </div>
                            <button
                                type="button"
                                className={`text-white px-1 py-2 mt-3 mb-2 rounded font_basic w-4/12 w-md:3/12 ${!reasonMsg ? "disable_btn" : 'bg_green'}`}
                                onClick={cancelSelectedOrder}
                                disabled={reasonMsg ? false : true}
                            >
                                Cancel Order
                            </button>
                        </div>
                    </div>
                </div> 
                : null}

        </>
    )
}

export default ViewOrderDetails;